<template>
  <v-card height="100%" color="backgroundColor">
    <h1 class="primary--text ma-4">Statistiques Etablissement</h1>
    <v-row class="ma-0 pa-0">
      <v-col cols="12" md="4" lg="3">
        <StatistiqueNav
          :InfoEtab="InfoEtab"
          :datemin="datemin"
          :datemax="datemax"
        />
      </v-col>
      <v-col cols="12" md="8" lg="9" align-center>
        <StatistiqueMain
          :datas="dataEtab"
          @changedatemin="setDatemin"
          @changedatemax="setDatemax"
        />
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import StatistiqueNav from "./../components/StatistiqueVueComponent/StatistiqueNav.vue";
import StatistiqueMain from "./../components/StatistiqueVueComponent/StatistiqueMain.vue";
import AdminService from "../services/AdminService";
import StatistiquesService from "../services/statistiquesService";
export default {
  components: {
    StatistiqueNav,
    StatistiqueMain,
  },
  data() {
    return {
      datemin: new Date(1999, 7, 28),
      datemax: new Date(),
      colors: [
        "#034155",
      "#d5dde1",
      "#fb4d61",
      "#f7cfd3",
      "#67afb1",
      "#845f92",
      "#DC0000",
      "#ABCC01",
      "#019934",
      "#0099BB",
      "#343399",
      "#883388",
      ],
      mois: [
        "Janvier",
        "Février",
        "Mars",
        "Avril",
        "Mai",
        "Juin",
        "Juillet",
        "Août",
        "Septembre",
        "Octobre",
        "Novembre",
        "Décembre",
      ],
      InfoEtab: {},

      loaded: false,
      options: { startDate: "", endDate: "" },
      retourBack: {
        nbEleveEtab: 25,
        nbEleveEtabActif: 1,
        listStatClasseEtab: [],
        listStatEtabJour: [
         ],
      },
    };
  },

  mounted() {
    AdminService.getEtablissementByID(this.$route.params.id).then((element) => {
      this.InfoEtab = element.data;
    });
    this.initialize();
  },
  watch: {
    "options.startDate"() {
      this.initialize();
    },
    "options.endDate"() {
      this.initialize();
    },
  },
  methods: {
    initialize() {
      this.loading = true;
      const { startDate, endDate } = this.options;
      StatistiquesService.postStatsEtablissement(
        this.$route.params.id,
        startDate,
        endDate
      ).then((element) => {
        this.retourBack = element.data;
        this.retourBack.loaded = true;
      });
    },
    setDatemin(payload) {
      this.options.startDate =
        payload.message.substring(8, 10) +
        "/" +
        payload.message.substring(5, 7) +
        "/" +
        payload.message.substring(0, 4);
    },
    setDatemax(payload) {
      this.options.endDate =
        payload.message.substring(8, 10) +
        "/" +
        payload.message.substring(5, 7) +
        "/" +
        payload.message.substring(0, 4);
    },
  },
  computed: {
    dataEtab() {
      return [
        {
          name: "Elèves activés",
          type: "donuts",
          loaded: this.retourBack.loaded,
          chartData: this.ElevesActifsDatas,
        },

        {
          name: "Elèves activés par classe",
          type: "bar",
          loaded: this.retourBack.loaded,
          chartData: this.ElevesActifsNivDatas,
        },

        {
          name: "Activité par jour",
          type: "line",
          loaded: this.retourBack.loaded,
          chartData: this.ElevesActifsDateDatas,
        },
      ];
    },

    ElevesActifsDatas() {
      let chartData = {
        labels: ["Activés", "Désactivés"],
        datasets: [
          {
            backgroundColor: [this.colors[0], this.colors[1]],
            data: [
              this.retourBack.nbEleveEtabActif,
              this.retourBack.nbEleveEtab - this.retourBack.nbEleveEtabActif,
            ],
            datalabels: {
              color: [this.colors[1], this.colors[0]],
            },
          },
        ],
      };
      return chartData;
    },
    ElevesActifsNivDatas() {
      let nbrelevesinactif = [];
      let nbreleveactif = [];
      let chartData = {
        labels: [],
        datasets: [],
      };
      this.retourBack.listStatClasseEtab.forEach((niveau) => {
        chartData.labels.push(niveau.nomClasse);
        nbrelevesinactif.push(niveau.nbEleveClasse - niveau.nbEleveActifClasse);
        nbreleveactif.push(niveau.nbEleveActifClasse);
      });
      chartData.datasets = [
        {
          type: "bar",
          label: "Elèves activés",
          backgroundColor: this.colors[0],
          data: nbreleveactif,
          datalabels: {
            color: this.colors[1],
          },
        },
        {
          type: "bar",
          label: "Elèves désactivés",
          backgroundColor: this.colors[1],
          data: nbrelevesinactif,
          datalabels: {
            color: this.colors[0],
          },
        },
      ];
      return chartData;
    },
    ElevesActifsDateDatas() {
      let nbreleves = [];
      let nbreleveactif = [];
      let chartData = {
        labels: [],
        datasets: [],
        maxValue: 1,
      };
      this.retourBack.listStatEtabJour.forEach((jour) => {
        let date =
          jour.date.substring(8, 10) +
          " " +
          this.mois[+jour.date.substring(5, 7) - 1] +
          " " +
          jour.date.substring(0, 4);

        chartData.labels.push(date);
        if (chartData.maxValue < jour.nbEleve) {
          chartData.maxValue = jour.nbEleve;
        }
        nbreleves.push(jour.nbEleve);
        if (chartData.maxValue < jour.nbEleveActif) {
          chartData.maxValue = jour.nbEleveActif;
        }
        nbreleveactif.push(jour.nbEleveActif);
      });
      chartData.datasets = [
        {
          type: "line",
          label: "Elèves créés",
          borderColor: this.colors[4],
          backgroundColor: "transparent",
          borderWidth: 1,
          data: nbreleves,
          datalabels: {
            align: "end",
            color: this.colors[4],
          },
        },
        {
          type: "line",
          label: "Elèves activés",
          borderColor: this.colors[0],
          backgroundColor: "transparent",
          borderWidth: 1,
          data: nbreleveactif,
          datalabels: {
            align: "end",
            color: this.colors[0],
          },
        },
        
      ];
      return chartData;
    },
  },
};
</script>

<style>
</style>
